@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Luckiest+Guy&display=swap");

.App-header {
  background-image: url("cover.png");
  /* Relative to the public folder */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  /* Full viewport height */
}

.App {
  text-align: center;
  font-family: "Arial", sans-serif;
  background-color: #fff;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.App-header {
  position: relative;
  color: #333;
}

.App-header .socials.top {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px;
  width: 220px;
}

.App-header a {
  margin: 7px;
  text-decoration: none;
}

.App-header .soc {
  width: 36px;
  background: white;
  border-radius: 50%;
}

.App-header .content {
  padding-top: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header .content-box {
  margin: 10px 0;
}

.App-header .content img {
  width: 420px;
  margin: 40px 0;
}

.App-header .maskon {
  position: relative;
  display: inline-block;
}

h3 {
  font-family: "Luckiest Guy", cursive;
  letter-spacing: 2px;
  font-size: 2em;
  background: black;
  color: #ff9ff3;
  margin: 0;
  padding: 10px 18px;
  text-transform: uppercase;
}

.App-header .banner {
  width: 100%;
}

.App-header h1 {
  font-size: 2.5rem;
  margin: 0;
}

.App-header .copy-box {
  display: flex !important;
  flex-direction: row;
  margin-bottom: 20px;
}

.content .buy {
  display: block;
  width: 100%;
  font-family: "Luckiest Guy", cursive;
  letter-spacing: 2px;
  font-size: 2em;
  background: black;
  color: #ff9ff3;
  margin: 0;
  padding: 12px 0;
  text-transform: uppercase;
}

p.email {
  padding: 15px 0;
  font-size: 0.61em;
  opacity: 0.6;
}

.socials {
  padding: 10px 0px;
}

.socials a {
  margin-bottom: 4px;
  display: inline-block;
}

@media (max-width: 600px) {

  .App-header .maskon h3,
  .content .buy {
    font-size: 1em;
  }

  .App-header .content img {
    width: 80%;
  }

  .App-header .copy-box {
    font-size: 0.6em;
  }
}

.buy {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6856D1;
  padding: 40px 0;
}

.buy h3 {
  color: white;
}

.buy video {
  margin: 25px auto;
}

.buy .socials {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.buy .cexx {
  height: 40px;
  margin: 0 12px 12px 0;
}

.vid-container {
  max-width: 900px;
}

.buy .cexx.gate {
  height: 60px;
}

/* Meme Generator Styles */
.meme-generator {
  font-family: "Luckiest Guy", cursive;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ff9ff3;
  padding: 0 0 1rem 0;
  overflow-y: auto;
}

.m-tag {
  color: #000;
}

.meme-content {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: visible;
}

@media (min-width: 768px) {
  .meme-generator h1 {
    font-size: 6em;
    margin: 1rem 0 2rem 0;
  }

  .meme-content {
    flex-direction: row;
    height: calc(100vh - 10rem);
    overflow-y: hidden;
  }
}

.canvas-and-controls-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  gap: 1rem;
}

.meme-canvas {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meme-controls {
  width: 90%;
  max-width: 600px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: visible;
  margin: 0 auto;
}

.sticker-menu {
  height: auto;
  overflow-y: visible;
  flex-grow: 1;
}

.sticker-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  height: 100%;
  overflow-y: auto;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .meme-content {
    flex-direction: column;
    height: auto;
    overflow-y: visible;
  }

  .meme-controls {
    padding: 2rem;
    justify-content: space-between;
    overflow-y: auto;
    max-height: calc(100vh - 12rem);
    flex: 35;
    max-width: 400px;
  }

  .sticker-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    padding: 0.5rem;
  }
}

.canvas-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.canvas-wrapper canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
  border: none;
  background-color: transparent;
  object-fit: contain;
}

.file-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-button {
  background-color: #000000;
  color: #ff9ff3;
  border: 2px solid #ff9ff3;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  font-weight: bold;
  text-transform: uppercase;
}

.upload-button:hover {
  background-color: #ff9ff3;
  color: #000000;
}

.meme-controls {
  flex: 3;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  overflow-y: visible;
}

@media (max-width: 767px) {
  .meme-controls {
    max-height: none;
    overflow-y: visible;
  }

  .canvas-and-controls-container {
    height: auto;
  }

  .meme-canvas {
    height: auto;
    flex: none;
  }

  .sticker-menu {
    height: auto;
    overflow-y: visible;
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .meme-controls {
    padding: 0 2rem 2rem 2rem;
    justify-content: space-between;
    overflow-y: auto;
    flex: 35;
    /* Add this line to ensure max-width is applied */
  }
}

.image-type-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.image-type-button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: 2px solid #ff9ff3;
  background-color: #000000;
  color: #ff9ff3;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
  text-transform: uppercase;
}

.image-type-button.active {
  background-color: #ff9ff3;
  color: #000000;
}

.image-type-button:hover {
  background-color: #ff9ff3;
  color: #000000;
}

.sticker-menu {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .sticker-menu {
    height: auto;
    max-height: 500px;
    overflow-y: auto;
  }

  .sticker-grid {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
  }
}

.sticker-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-height: 100%;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .sticker-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.sticker-button {
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 0;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticker-button:hover {
  background-color: #ff9ff3;
}

.pose-preview {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

.action-buttons button {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  font-weight: bold;
  text-transform: uppercase;
}

.reset-button {
  background-color: #ff9ff3;
  color: #000000;
  border: 2px solid #ff9ff3;
}

.reset-button:hover {
  background-color: #1f1f1f;
  color: #ff9ff3;
}

.download-button {
  background-color: #1f1f1f;
  color: #ff9ff3;
  border: 2px solid #ff9ff3;
}

.download-button:hover {
  background-color: #ff9ff3;
  color: #000000;
}

.icon {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.konvajs-content {
  border: 1px solid #ff9ff3;
  max-width: 100% !important;
  max-height: 100% !important;
}

.konvajs-content canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
}

canvas {
  cursor: pointer;
}

.sticker-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ff9ff3;
}

.control-button {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.control-button:hover {
  background-color: rgba(255, 159, 243, 0.2);
}

.control-button svg {
  width: 20px;
  height: 20px;
  color: #ff9ff3;
}

.sticker-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add these styles to your existing CSS */

.image-type-button:disabled,
.sticker-button:disabled,
.download-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.image-type-button:disabled:hover,
.sticker-button:disabled:hover,
.download-button:disabled:hover {
  background-color: #000000;
  color: #ff9ff3;
}

.meme-canvas {
  position: relative;
}

.centered-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.delete-button,
.mirror-button {
  /* Add styles similar to other buttons */
}

.delete-button:disabled,
.mirror-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add these styles to your existing CSS */

.sticker-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ff9ff3;
  z-index: 100;
}

.control-button {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.control-button:hover {
  background-color: rgba(255, 159, 243, 0.2);
}

.control-button svg {
  width: 20px;
  height: 20px;
  color: #ff9ff3;
}

.sticker-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .meme-canvas {
    width: 100vw;
    max-width: 100vw;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .canvas-wrapper {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }

  .canvas-wrapper canvas {
    width: 100vw !important;
    height: auto !important;
    max-width: none !important;
  }

  .meme-content {
    padding: 0;
  }

  .canvas-and-controls-container {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }

  .meme-generator {
    padding: 0;
  }

  .meme-generator h1 {
    margin: 1rem 0;
  }
}

/* Mobile padding */
@media screen and (max-width: 768px) {
  .meme-generator {
    padding: 40px 0;
  }
}

/* Desktop padding (larger) */
@media screen and (min-width: 769px) {
  .meme-generator {
    padding: 80px 0;
  }
}